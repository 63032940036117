import React, { useState } from "react"
import Button from "../ui/Button"
import axios from "axios"
import { Link, navigate } from "gatsby"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import Loading from "../ui/Loading"

const RegisterForm = () => {
  const [token, setToken] = useState("")
  const [forename, setForename] = useState("")
  const [surname, setSurname] = useState("")
  const [serverState, setServerState] = useState({
    submitting: false,
    status: {
      success: false,
      errors: [],
    },
  })

  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleServerResponse = (success, errors, form) => {
    setServerState({
      submitting: false,
      status: { success, errors },
    })
    if (success) {
      form.reset()
      // Navigate to thank you page
      navigate("/thanks")
    }
  }

  const handleForenameChange = e => {
    // pattern="^[A-Za-zÀ-ÿ ,.'-]+$"
    const result = e.target.value.replace(/[^A-Za-zÀ-ÿ '-]/gi, "")
    setForename(result)
  }
  const handleSurnameChange = e => {
    // pattern="^[A-Za-zÀ-ÿ ,.'-]+$"
    const result = e.target.value.replace(/[^A-Za-zÀ-ÿ '-]/gi, "")
    setSurname(result)
  }

  const handleOnSubmit = async e => {
    e.preventDefault()

    const token = await executeRecaptcha("yourAction")
    setToken(token)

    const form = e.target

    setServerState({ submitting: true })

    const data = new FormData(form)
    let formObject = Object.fromEntries(data.entries())

    data.append(
      "dob",
      formObject["dob-year"] +
        "-" +
        String(formObject["dob-month"]).padStart(2, "0") +
        "-" +
        String(formObject["dob-day"]).padStart(2, "0")
    )
    data.append("g-recaptcha-response", token)

    axios({
      method: "post",
      url: `${process.env.GATSBY_API_ENDPOINT}/api/register`,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    })
      .then(res => {
        if (res.data.success !== true) {
          handleServerResponse(false, res.data.errors, form)
        } else {
          handleServerResponse(true, null, form)
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          handleServerResponse(
            false,
            ["Invalid reCAPTCHA token, refreshed. Submit to try again."],
            form
          )
        }
        if (error.response.status === 422) {
          handleServerResponse(false, [error.response?.data?.message], form)
        }
      })
  }

  return (
    <>
      <h3>Register Today</h3>
      <form className={``} onSubmit={handleOnSubmit}>
        <label className={``} htmlFor="grid-first-name">
          Forename
        </label>
        <input
          className={``}
          id="grid-first-name"
          name="forename"
          value={forename}
          type="text"
          placeholder="eg. John"
          onInput={handleForenameChange}
          required
        />
        <label className={``} htmlFor="grid-last-name">
          Surname
        </label>
        <input
          className={``}
          id="grid-last-name"
          name="surname"
          value={surname}
          type="text"
          placeholder="eg. Taylor"
          onInput={handleSurnameChange}
          required
        />{" "}
        <label className={``} htmlFor="dob-day">
          Date of Birth
        </label>
        <div className={`grid grid-cols-3 gap-1 sm:gap-4 md:gap-6`}>
          <div className={``}>
            <input
              className={``}
              id="dob-day"
              name="dob-day"
              type="number"
              min="1"
              max="31"
              placeholder="DD"
              required
            />
          </div>
          <div className={``}>
            <input
              className={``}
              id="dob-month"
              name="dob-month"
              type="number"
              min="1"
              max="12"
              placeholder="MM"
              required
            />
          </div>
          <div className={``}>
            <input
              className={``}
              id="dob-year"
              name="dob-year"
              type="number"
              min="1900"
              max="2022"
              placeholder="YYYY"
              required
            />
          </div>
        </div>
        <div className={``}>
          <div className={``}>
            <label className={``} htmlFor="grid-password">
              Email
            </label>
            <input
              className={``}
              id="email"
              type="text"
              name="email"
              required
              placeholder="eg. johntaylor@gmail.com"
            />
          </div>
        </div>
        {serverState.status?.errors?.map((error, index) => {
          return (
            <div
              key={`error_${index}`}
              className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
              role="alert"
            >
              {error}
            </div>
          )
        })}
        {serverState.submitting === true ? (
          <Loading />
        ) : (
          <Button type="submit" text="Register" />
        )}
        <p className={`mb-0`}>
          By registering you agree to accept the Dine With Us Discount{" "}
          <Link to="/terms">Terms &amp; Conditions</Link> and our third party
          supplier{" "}
          <a
            href="https://www.mbplc.com/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </p>
      </form>
    </>
  )
}

export default RegisterForm
