import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BannerImage from "../components/content/BannerImage"
import RegisterForm from "../components/content/RegisterForm"
import circleImage from "../images/supplier_discount-register_circle.png"
import contentImage from "../images/Masterclass7.jpg"

const Index = () => {
  return (
    <Layout home={true}>
      <Seo title="Supplier Discount - Mitchells & Butlers" description="" />

      <BannerImage />
      <a id="register" />

      <div className={`container z-10 relative -mt-10`}>
        <div
          className={`bg-theme-white mbplc-shadow rounded-2xl w-full md:w-5/6 mx-auto p-10 md:p-20 grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-20 lg:gap-48 mb-10 md:mb-24`}
        >
          <div className={``}>
            <RegisterForm />
          </div>
          <div className={``}>
            <img
              src={circleImage}
              className={``}
              alt="Inside a blue border, there is a woman smiling into her hands on the left and a team member taking an order on the right"
            />
          </div>
        </div>
        <div className={`w-full mx-auto md:w-5/6 lg:w-2/3 pb-10 md:pb-32`}>
          <p>
            You can use your unique 20% discount code when dining and drinking
            out in some of Britain&#39;s best loved brands including All Bar
            One, Harvester, Toby Carvery, Miller & Carter and Sizzling Pubs.
            This discount is exclusively for you to enjoy visits with friends &
            family in any Mitchells & Butlers venue.
          </p>
          <img
            src={contentImage}
            className={`rounded-2xl overflow-hidden mb-8 md:float-right md:ml-10 lg:ml-28 md:w-1/2`}
            alt="All Bar One Cocktail Masterclass"
          />
          <p>
            Once you&#39;ve registered your details using the simple sign-up
            form, we&#39;ll double check you&#39;re an official member of our
            &#39;VIP supplier list&#39;. Please use your company email address
            so we can verify your VIP status.
          </p>
          <p className="font-bold">
            Once you&#39;ve verified your email address, we will present you
            with the link to download the new Supplier Dine With Us app where
            your discount voucher will be waiting for you!
          </p>
          <p>
            From time to time, we might even surprise you with more money saving
            offers, so you can enjoy a visit even more regularly - just to say
            thank you!{" "}
          </p>
          <p>
            Got a question?{" "}
            <a
              href="https://surveys.reputation.com/surveys3/run?tenantKey=12543018a7d&templateKey=mabfandf&locationCode=000300&stylesheet=MAB%20General&locale=en_US"
              rel="noreferrer"
              target="_blank"
            >
              Contact support
            </a>
            .{" "}
          </p>
        </div>
      </div>
      <div
        className={`absolute block bg-theme-blue-light w-[852px] aspect-square rounded-full bottom-[-230px] md:bottom-[-120px] left-[-550px] z-0`}
      />
      <div
        className={`absolute block bg-theme-blue-light w-[648px] aspect-square rounded-full top-[798px] right-[-212px] z-0`}
      />
    </Layout>
  )
}

export default Index
