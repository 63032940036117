import React from "react"
import image from "../../images/_RDX3859_cy.jpg"

const BannerImage = () => {
  return (
    <div
      className={`ml-3 lg:ml-10 bg-center bg-cover rounded-bl-[120px] md:rounded-bl-[240px] lg:rounded-bl-[520px] overflow-hidden`}
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className={`pt-32 pb-48 bg-gradient-to-r from-black to-black/30`}>
        <div className={`container`}>
          <h1 className={`max-w-[815px] md:ml-1/12 text-theme-white`}>
            Enjoy 20% off Supplier Discount
          </h1>
          <p
            className={`text-theme-white max-w-[434px] md:ml-1/12 md:text-2xl md:leading-10 mb-0 text-shadow`}
          >
            As a valued Mitchells & Butlers supplier you can register your
            contact details to receive a 20% off discount code to use when you
            dine and drink in one of our venues.{" "}
          </p>
        </div>
      </div>
    </div>
  )
}

export default BannerImage
